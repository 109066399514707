<template>
  <div>

    <div class="p-8">
      <b-row>
        <b-col>
          <tip-label :tip="$t('PAGES.EVENTS.TIP_SUBEVENTS')">
            <h5 class="mb-4 mt-4">{{$t('PAGES.EVENTS.HEADER_SUBEVENTS')}}</h5>
          </tip-label>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="8">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input id="field-subevents" type="checkbox" name="" v-model="settings.subevents"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('PAGES.EVENTS.CHECKBOX_SUBEVENTS') }}</span>
          </div>

        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <tip-label :tip="$t('PAGES.EVENTS.TIP_ENABLED_EVENT_DINTERO')">
            <h5 class="mb-4 mt-4">{{$t('PAGES.EVENTS.HEADER_ENABLED_EVENT_DINTERO')}}</h5>
          </tip-label>
        </b-col>
      </b-row>



      <b-row>
        <b-col md="8">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input id="field-enabled_event_dintero" type="checkbox" name="" v-model="settings.enabled_event_dintero"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('PAGES.EVENTS.CHECKBOX_ENABLED_EVENT_DINTERO') }}</span>
          </div>

        </b-col>
      </b-row>


      <b-row class="mt-8">
        <b-col md="8">

          <b-form-group id="url_after_event_signup-group">
            <tip-label class="mb-2" for="url-after-event-signup" :tip="$t('PAGES.EVENTS.TIP_URL_FORWARD')">{{$t('PAGES.EVENTS.HEADER_URL_FORWARD')}}</tip-label>
              <b-form-input
                id="url-after-event-signup"
                v-model="settings.url_after_event_signup"
                type="text"
                :placeholder="'https://memlist.se'"
              />
              <b-form-text>{{ $t('PAGES.SETTINGS.REQUIRES_HTTP') }}</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-8">
        <b-col md="8">
          <b-button type="submit" variant="primary" @click.prevent="save_data">{{ $t('COMMON.SAVE') }}</b-button>
        </b-col>
      </b-row>
    </div>

  </div>

</template>

<style lang="scss" scoped>
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import ColorPickerInput from '@/view/components/ColorPickerInput';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'EventSettings',

  components: { TipLabel, RequiredFieldsInfo, ColorPickerInput },

  mixins: [ toasts ],

  computed: {
    ...mapGetters(['currentCompanyId']),
  },

  watch: {},

  methods: {
    async load_settings() {
      try {
        const response = await axios.get(`/company/${this.currentCompanyId}`);
        this.settings = response.data.company_settings;
      } catch (error) {
        this.toastr('danger', this.$t('COMMON.ERROR'), error);
      }
    },

    async save_data() {
      try {
        const response = await axios.put(`/company/company_settings/${this.currentCompanyId}`, {
          enabled_event_dintero: this.settings.enabled_event_dintero,
          subevents: this.settings.subevents
        });

        if (response.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
      }
    },
  },

  mounted() {
    this.load_settings();
  },

  data() {
    return {
      settings: {
        subevents: false,
        enabled_event_dintero: false
      }
    };
  }

};

</script>
